import ArticleCardPage from "./ArticlePage";
import React, {useState} from 'react';


const article = {
    id: 2,
    title: "Subscribe to News Letter",
    author: "",
    description: `Stay informed, stay empowered! Sign up for our newsletter to unlock a world of exclusive news, insightful analyses, 
    and groundbreaking stories delivered straight to your inbox - absolutely free. 
    Join our community of informed readers who trust us to deliver timely updates on the topics that matter most to you. 
    Don't miss out on the latest developments, trends, and expert perspectives. Sign up now and take your knowledge to the next level!
    `,
    date: "2022-02-28",
    urlToImage: "/ai_image_Atip.png"
};

function NewsLetter() {


    return (
        <div>
            {/*<GlobalStyle />*/}
            <div className="website-name">
                <header className="website__header">
                    <div className="masthead_menu_container">
                        <button className="masthead__menu" aria-label="dropdown menu"></button>
                        {/*<div className="masthead__user-login"><span className="masthead__user-label">Login</span></div>*/}
                    </div>
                    <div className="masthead__title"><a alt="Rectangle News" href="/">
                        <div className="masthead__title__text">Rectangle News</div>
                    </a></div>
                    {/*<div className="masthead__interactive"><a*/}
                    {/*    href="/"*/}
                    {/*    className="masthead__btn-subscribe">SUBSCRIBE To News letter </a>*/}
                    {/*    /!*<button className="masthead__search" aria-label="site search"><label htmlFor="queryly_toggle"><img*!/*/}
                    {/*    /!*    src="" alt=""*!/*/}
                    {/*    /!*    width="18" height="18"/></label></button>*!/*/}
                    {/*</div>*/}
                </header>
            </div>
            <div className="spacer"></div>
            {/* Element to create space */}
            <div className="results-wrapper">

                <div className="result-column-pages">
                    <div className="top-news-list">
                        <h3>Sign up for free newsletters and get more delivered to
                            your inbox, "The News That Matters"</h3>
                    </div>
                    {ArticleCardPage(article)}

                    <div className="spacer"></div>


                </div>
                <div className="result-column-pages">
                    {/*<h5 style={{textAlign: 'left'}}>Sponsored</h5>*/}
                    {/*<img src="https://source.unsplash.com/random/500x400?sig=${Math.random()}" alt="random_img"/>*/}
                    {/* Video */}
                    {/*<div className="video-container">*/}
                    {/*    <iframe src="https://www.youtube.com/embed/l3wRDBdUhhU" title="Random Video" width="00px"*/}
                    {/*            height="300px" frameBorder="0" allowFullScreen></iframe>*/}
                    {/*</div>*/}
                    {/*<AdSpot adText="Ad spot 1 text" width="500px" height="350px" />*/}
                    {/*<AdSpot adText="Ad spot 2 text" width="500px" height="350px" />*/}
                    {/*<RectHome AdSenseAd adText="Ad spot 1 text" adClient="ca-pub-8799681346916578" adSlot="7259874014"/>*/}
                </div>


            </div>

            <div className="rectFooter__footer">

            <div>
                <footer id="GlobalFooter" className="rectFooter__footer"
                        data-mini-video-player-dont-scroll-beyond-here="true" data-test="GlobalFooter"
                        data-analytics="Homepage-GlobalFooter">
                    <div className="rectFooter__wrapper">
                        <div>
                            <a href="//www.rectanglenews.com"></a></div>

                        <div className="rectFooter-border"></div>
                        <ul className="rectFooter-section">
                            <li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">News
                                Tips</h4><p
                                className="rectFooter-sectionText">Got a confidential news tip? We want to hear from
                                you.</p>
                                <a
                                    href="/NewsTips" className="rectFooter__sectionLink">Get In Touch</a></li>

                            {/*<li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">Advertise*/}
                            {/*    With Us</h4><a*/}
                            {/*    href="https://together.nbcuni.com/advertise/?utm_source=cnbc&amp;utm_medium=referral&amp;utm_campaign=property_ad_pages"*/}
                            {/*    className="rectFooter__sectionLink" target="_blank">Please Contact Us</a></li>*/}
                            <li className="rectFooter-sectionItem"><p
                                className="rectFooter-sectionSubText">© 2024 Rectangle Digital. All Rights Reserved.
                            </p></li>
                        </ul>
                        {/*<p className="rectFooter-info">© 2024 Rectangle Digital. All Rights Reserved. </p>*/}
                    </div>
                </footer>
            </div>
            </div>

        </div>
    );
}

export default NewsLetter;