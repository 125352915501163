import React from 'react';
import ArticleCardPage from "./ArticlePage";


const article = {
    id: 1,
    title: "Have a tip or story you think we should know about?",
    author: "",
    description: `We're all ears. Whether it's a news tip, an important issue that needs attention, or a personal experience you feel needs to be heard, 
    we're here to listen. Your anonymity is guaranteed, and your story could make a difference.
    `,
    content: `We value your input and encourage you to share any information you have anonymously. 
    Your insights could help us uncover important stories and make a positive impact. Send us your tips securely and anonymously 
    through "information@rectanglenews.com" and help us continue our commitment to transparency and accountability.
    Together, we can make a difference an share the world "The News That Matters"`,
    date: "2022-02-28",
    urlToImage: "/ai_image_Atip.png"
};


function NewsTips() {
    return (
        <div>

            <div className="website-name">
                <header className="website__header">
                    <div className="masthead_menu_container">
                        <button className="masthead__menu" aria-label="dropdown menu"></button>
                        {/*<div className="masthead__user-login"><span className="masthead__user-label">Login</span></div>*/}
                    </div>
                    <div className="masthead__title"><a alt="Rectangle News" href="/">
                        <div className="masthead__title__text">Rectangle News</div>
                    </a></div>
                    {/*<div className="masthead__interactive"><a*/}
                    {/*    href="/"*/}
                    {/*    className="masthead__btn-subscribe">SUBSCRIBE To News letter </a>*/}
                    {/*    /!*<button className="masthead__search" aria-label="site search"><label htmlFor="queryly_toggle"><img*!/*/}
                    {/*    /!*    src="" alt=""*!/*/}
                    {/*    /!*    width="18" height="18"/></label></button>*!/*/}
                    {/*</div>*/}
                </header>
            </div>
            <div className="spacer"></div>
            {/* Element to create space */}
            <div className="results-wrapper">

                <div className="result-column-pages">
                    <div className="top-news-list">
                        <h3>News Tips </h3>
                    </div>
                    {ArticleCardPage(article)}
                    <div className="spacer"></div>


                </div>
                <div className="result-column-pages">
                    {/*<h5 style={{textAlign: 'left'}}>Sponsored</h5>*/}
                    {/*<img src="https://source.unsplash.com/random/500x400?sig=${Math.random()}" alt="random_img"/>*/}
                    {/* Video */}
                    {/*<div className="video-container">*/}
                    {/*    <iframe src="https://www.youtube.com/embed/l3wRDBdUhhU" title="Random Video" width="500px"*/}
                    {/*            height="300px" frameBorder="0" allowFullScreen></iframe>*/}
                    {/*</div>*/}
                    {/*<AdSpot adText="Ad spot 1 text" width="500px" height="350px" />*/}
                    {/*<AdSpot adText="Ad spot 2 text" width="500px" height="350px" />*/}
                    {/*<RectHome AdSenseAd adText="Ad spot 1 text" adClient="ca-pub-8799681346916578" adSlot="7259874014"/>*/}
                </div>


            </div>

            <div className="rectFooter__footer">

            <div>
                <footer id="GlobalFooter" className="rectFooter__footer"
                        data-mini-video-player-dont-scroll-beyond-here="true" data-test="GlobalFooter"
                        data-analytics="Homepage-GlobalFooter">
                    <div className="rectFooter__wrapper">
                        <div>
                            <a href="//www.rectanglenews.com"></a></div>

                        <div className="rectFooter-border"></div>
                        <ul className="rectFooter-section">

                            <li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">Rectangle
                                Digital Newsletters</h4><p
                                className="rectFooter-sectionText">Sign up for free newsletters and get more
                                delivered
                                to
                                your inbox</p><a href="/NewsLetter"
                                                 className="rectFooter__sectionLink">Sign
                                Up Now</a></li>
                            {/*<li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">Advertise*/}
                            {/*    With Us</h4><a*/}
                            {/*    href="https://together.nbcuni.com/advertise/?utm_source=cnbc&amp;utm_medium=referral&amp;utm_campaign=property_ad_pages"*/}
                            {/*    className="rectFooter__sectionLink" target="_blank">Please Contact Us</a></li>*/}
                            <li className="rectFooter-sectionItem"><p
                                className="rectFooter-sectionSubText">© 2024 Rectangle Digital. All Rights Reserved.
                            </p></li>
                        </ul>
                        {/*<p className="rectFooter-info">© 2024 Rectangle Digital. All Rights Reserved. </p>*/}
                    </div>
                </footer>
            </div>
            </div>


            </div>
            );
            }

            export default NewsTips;