import React, {useEffect, useState} from "react";
import ArticleCard from "./Article";
import ScrollToTopButton from "./Scroll";
import {get} from "axios";

// AdSpot component to render an ad spot
const AdSenseAd = ({ adClient, adSlot }) => {
    useEffect(() => {
        // Load Google AdSense script
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return (
        <div>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client={adClient}
                data-ad-slot={adSlot}
                data-ad-format="auto"
                data-full-width-responsive="true"
            />
        </div>
    );
};


const RectHome = () => {

    const [usNews, setusNews] = useState([]);
    const [techNews, settechNews] = useState([]);
    const [indiaNews, setinNews] = useState([]);


    useEffect(() => {
        fetchUSNews();
    }, []);

    useEffect(() => {
        fetchTechNews();
    }, []);

    useEffect(() => {
        fetchInNews();
    }, []);


    const getdefault = async (feed) =>{
        try {
            const def_response = await fetch(`https://rectanglefeed.s3.us-east-2.amazonaws.com/${feed}_feed.json`)
            const def_data = await def_response.json()
            if (feed === 'us' || feed === 'usmore' ) {
                setusNews(def_data.articles.filter(article => article.title !== '[Removed]' || article.urlToImage !== null));
            }else if (feed === 'tech' || feed === 'techmore'){
                settechNews(def_data.articles.filter(article => article.title !== '[Removed]' || article.urlToImage !== null));
            }else if (feed === 'india' || feed === 'indiamore'){
                setinNews(def_data.articles.filter(article => article.title !== '[Removed]' || article.urlToImage !== null));
            }else {
                console.error('not defined feed options')
                return [];
            }
        } catch (error) {
            console.error('Error fetching default news..', error);
            return []; // Return an empty array in case of error

        }
    }

    const fetchUSNews = async (page_select = 'us') => {
        try {
            // Fetch tech news data from an API (replace URL with your API endpoint)
            //const response = await fetch(`https://newsapi.org/v2/top-headlines?country=us&pageSize=10&page=${page_select}&apiKey=4463400343c94307a995ef39858caaaf`);
            const response = await fetch(`https://feed.rectanglenews.com/api/v1/news/${page_select}`,  {signal:AbortSignal.timeout(4000)});
            // if (!response.ok) {
            //     console.error('Error fetching top news from DB..getting default:');
            //     await getdefault(page_select);
            //
            // }
            const data = await response.json();
            //console.log("redis data " + data.users.articles[0].author);
            setusNews(data.users.articles.filter(article => article.title !== '[Removed]' || article.urlToImage !== null));

        } catch (error) {
            console.error('Error fetching top news..getting default:', error);
            //return []; // Return an empty array in case of error
            await getdefault(page_select);
        }
    };

    const fetchTechNews = async (page_select = 'tech') => {
        try {
            // Fetch tech news data from an API (replace URL with your API endpoint)
            const response = await fetch(`https://feed.rectanglenews.com/api/v1/news/${page_select}`,  {signal:AbortSignal.timeout(3000)});
            // if (!response.ok) {
            //     throw new Error('Failed to fetch tech news from redis');
            // }
            const data = await response.json();
            //console.log("redis data " + data.users.articles[0].author);
            settechNews(data.users.articles.filter(article => article.title !== '[Removed]' || article.urlToImage !== null));

        } catch (error) {
            console.error('Error fetching tech news..getting default :', error);
            //return []; // Return an empty array in case of error
            await getdefault(page_select);
        }
    };

    const fetchInNews = async (page_select = 'india') => {
        try {
            // Fetch tech news data from an API (replace URL with your API endpoint)
            //const response = await fetch(`https://newsapi.org/v2/top-headlines?country=us&pageSize=10&page=${page_select}&apiKey=4463400343c94307a995ef39858caaaf`);
            const response = await fetch(`https://feed.rectanglenews.com/api/v1/news/${page_select}`,  {signal:AbortSignal.timeout(3000)});
            // if (!response.ok) {
            //     throw new Error('Failed to fetch tech news from redis');
            // }
            const data = await response.json();
            //console.log("redis data " + data.users.articles[0].author);
            setinNews(data.users.articles.filter(article => article.title !== '[Removed]' || article.urlToImage !== null));

        } catch (error) {
            console.error('Error fetching India news..getting default:', error);
            //return []; // Return an empty array in case of error
            await getdefault(page_select);
        }
    };



    return (

        <div>

            <div className="website-name">
                <header className="website__header">
                    <div className="masthead_menu_container">
                        <button className="masthead__menu" aria-label="dropdown menu"></button>
                        {/*<div className="masthead__user-login"><span className="masthead__user-label">Login</span></div>*/}
                    </div>
                    <div className="masthead__title"><a href="/">
                        <div className="masthead__title__text">Rectangle News</div>
                    </a></div>
                    {/*<div className="masthead__interactive"><a*/}
                    {/*    href="/"*/}
                    {/*    className="masthead__btn-subscribe">SUBSCRIBE To News letter </a>*/}
                    {/*    /!*<button className="masthead__search" aria-label="site search"><label htmlFor="queryly_toggle"><img*!/*/}
                    {/*    /!*    src="" alt=""*!/*/}
                    {/*    /!*    width="18" height="18"/></label></button>*!/*/}
                    {/*</div>*/}
                </header>
            </div>
            <div className="spacer"></div>
            {/* Element to create space */}

            <div className="results-wrapper">

                {/* Call each function and place the results side by side */}
                <div id="usmore" className="result-column">
                    <div className="top-news-list">
                        <h3>US News</h3>
                    </div>
                    {usNews.map((article, index) => (
                        <ArticleCard key={index} article={article}/>
                    ))}
                    <div className="spacer"></div>
                    <ScrollToTopButton fetchNews={fetchUSNews} customParameter='usmore'/>
                </div>
                <div id="techmore" className="result-column">
                    <div className="top-news-list">
                        <h3>Tech News</h3>
                    </div>
                    {techNews.map((article, index) => (
                        <ArticleCard key={index} article={article}/>
                    ))}
                    <div className="spacer"></div>
                    <ScrollToTopButton fetchNews={fetchTechNews} customParameter='techmore'/>
                </div>
                <div id="indiamore" className="result-column">
                    <div className="top-news-list">
                        <h3>India News</h3>
                    </div>
                    {indiaNews.map((article, index) => (
                        <ArticleCard key={index} article={article}/>
                    ))}
                    <div className="spacer"></div>
                    <ScrollToTopButton fetchNews={fetchInNews} customParameter='indiamore'/>
                </div>
                {/*<div className="result-column">*/}
                {/*    <h5 style={{textAlign: 'left'}}>Sponsored</h5>*/}
                {/*    /!*<img src="https://source.unsplash.com/random/500x400?sig=${Math.random()}" alt="random_img"/>*!/*/}
                {/*    /!* Video *!/*/}
                {/*    <div className="video-container">*/}
                {/*        <iframe src="https://www.youtube.com/embed/l3wRDBdUhhU" title="Random Video" width="500px"*/}
                {/*                height="300px" allowFullScreen></iframe>*/}
                {/*    </div>*/}
                {/*    /!*<AdSpot adText="Ad spot 1 text" width="500px" height="350px" />*!/*/}
                {/*    /!*<AdSpot adText="Ad spot 2 text" width="500px" height="350px" />*!/*/}
                {/*    <AdSenseAd adText="Ad spot 1 text" adClient="ca-pub-xxxxx16578" adSlot="7259874014"/>*/}
                {/*</div>*/}
            </div>

            <div className="rectFooter__footer">

            <div>
                <footer id="GlobalFooter" className="rectFooter__footer"
                        data-mini-video-player-dont-scroll-beyond-here="true" data-test="GlobalFooter"
                        data-analytics="Homepage-GlobalFooter">
                    <div className="rectFooter__wrapper">
                        <div>
                            <a href="//www.rectanglenews.com"></a></div>

                    <div className="rectFooter-border"></div>
                        <ul className="rectFooter-section">
                            <li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">News
                                Tips</h4><p
                                className="rectFooter-sectionText">Got a confidential news tip? We want to hear from
                                you.</p>
                                <a
                                    href="/NewsTips" className="rectFooter__sectionLink">Get In Touch</a></li>
                            <li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">Rectangle
                                Digital Newsletters</h4><p
                                className="rectFooter-sectionText">Sign up for free newsletters and get more delivered
                                to
                                your inbox</p><a href="/NewsLetter" className="rectFooter__sectionLink">Sign
                                Up Now</a></li>
                            {/*<li className="rectFooter-sectionItem"><h4 className="rectFooter-sectionTitle">Advertise With Us</h4><a*/}
                            {/*    href="https://together.nbcuni.com/advertise/?utm_source=cnbc&amp;utm_medium=referral&amp;utm_campaign=property_ad_pages"*/}
                            {/*    className="rectFooter__sectionLink" target="_blank">Please Contact Us</a></li>*/}
                            <li className="rectFooter-sectionItem"><p
                                className="rectFooter-sectionSubText">© 2024 Rectangle Digital. All Rights Reserved.
                                </p></li>
                        </ul>
                        {/*<p className="rectFooter-info">© 2024 Rectangle Digital. All Rights Reserved. </p>*/}
                    </div>
                </footer>
            </div>
            </div>

        </div>

    )
        ;

};


export default RectHome;