
import { createGlobalStyle } from 'styled-components';

import NameOfYourFontWoff from './CentraNo2-Bold.woff2';
import NameOfYourFontWoff2 from './CentraNo2-Light.woff2';
import NameOfYourFontWoff3 from './Grifito-M-Bold.woff';
import NameOfYourFontWoff4 from './CentraNo2-Book.woff2';

export default createGlobalStyle`

    @font-face {
        font-family: 'CentraNo2-Bold';
        src: local('CentraNo2-Bold'), url(${NameOfYourFontWoff}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'CentraNo2-Light';
        src: local('CentraNo2-Light'), url(${NameOfYourFontWoff2}) format('woff2');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'CentraNo2-Book';
        src: local('CentraNo2-Book'), url(${NameOfYourFontWoff4}) format('woff2');
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: 'Grifito-M-Bold';
        src: local('Grifito-M-Bold'), url(${NameOfYourFontWoff3}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;