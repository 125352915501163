import React, {useState} from 'react';
import axios from 'axios';

function ArticleCardPage (article) {
    const [iframeSrc, setIframeSrc] = useState(""); // State to hold the src of the iframe
    // const boldWords = ['The News That Matters', 'information@rectanglenews.com'];
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === "" || !/\S+@\S+\.\S+/.test(email)) {
            setIsEmailValid(false);
        } else {
            try {
                setIsEmailValid(true);
                await axios.post('https://m.rectanglenews.com/send-email', { email });
                alert('Thank you for subscribing !');
                setEmail("");
            } catch (error) {
                console.error('Error sending email:', error);
                alert('Error sending email. Please try again later.');
            }

        }

    };

    // // Function to wrap bold tags around specified words
    // const highlightWords = (description, words) => {
    //     return words.reduce((formattedContent, word) => {
    //         const regex = new RegExp(`\\b${word}\\b`, 'gi');
    //         return formattedContent.replace(regex, `<b>${word}</b>`);
    //     }, description);
    // };
    //
    // // Apply bold formatting to article content
    // const formattedContent = highlightWords(article.description, boldWords);
    // //console.log (formattedContent );

    const key = article.id;
    if (key === 1) {
        return (
            <div className="article-card">
                <h3>{article.title}</h3>
                <p>{article.description}</p>
                <p>{article.content}</p>
                <img src={article.urlToImage} className="image"/>
            </div>
        );
    } else if (key === 2){
    return (
        <div className="article-card">
            <h3>{article.title}</h3>
            <p>{article.description}</p>
            {!isEmailValid ? <p>Please enter a valid email address</p> : null}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder=" Enter your email address here   "
                    value={email}
                    onChange={handleChange}
                    style={{
                        padding: "10px",
                        fontSize: "16px",
                        width: "500px",
                        border: "2px solid #ddd",
                        borderRadius: "5px",
                    }}
                />
                <button
                    type="submit" className="all-feed"

                >
                    Subscribe
                </button>
            </form>
            <img src={article.urlToImage} className="image"/>
        </div>

        );

    }
    else {
        // Default case when articleId doesn't match any specific article
        return (
            <div className="article-card">
                <h3>Oops something gone wrong !</h3>
                <img src={article.urlToImage} className="image"/>
            </div>
        );
    }

}

export default ArticleCardPage;