import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import GlobalFonts from './assets/fonts';
import RectHome from "./RectHome";
import NewsTips from "./NewsTips";
import NewsLetter from "./NewsLetter";


function App() {

    return (
        <>
            <GlobalFonts />
            <Routes>
                <Route path="/" element={<RectHome />} />
                <Route path="/NewsTips" element={<NewsTips />} />
                <Route path="/NewsLetter" element={<NewsLetter />} />
                {/*<Route path="/about" element={<About />} />*/}
            </Routes>
        </>

    );

};


export default App;
