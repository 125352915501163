import React, {useState} from 'react';


const ArticleCard = ({ article }) => {
    const [iframeSrc, setIframeSrc] = useState(""); // State to hold the src of the iframe

    return (
        <div className="article-card">
            <h3>{article.title}</h3>
            <p>{article.description }</p>
            <a href={article.url} target="_blank" rel="noopener noreferrer">
                Read more
            </a>
            <img src={article.urlToImage}  className="image"/>
        </div>
    );
};

export default ArticleCard;