import React, { useState } from 'react';

function ScrollToTopButton ({ fetchNews, customParameter }) {
    const [isVisible, setIsVisible] = useState(false);
    const [clicked, setClicked] = useState(false);

    // Function to handle the scroll event
    const handleScroll = () => {
        // Show the button when user scrolls down
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Function to scroll to the top of the page
    const scrollToTop = (section) => {
        const element = document.getElementById(section);
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: y,
            behavior: 'instant' // Optional: animated scrolling
        });
        //setIsVisible(false); // Hide the button after scrolling to top
    };

    // Add scroll event listener when component mounts
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const fetchMore = () => {
        fetchNews(customParameter);
        setClicked(true);
        scrollToTop(customParameter);
    };

    return (
        <div className="result-column">
            {/* Show the button if isVisible is true */}
            {isVisible && (
                // <button onClick={() => fetchUSNews(customParameter)} className="all-feed">
                <button onClick={fetchMore} className={`all-feed ${clicked ? 'grey-button' : ''}`}>
                    {clicked ? 'You are caught up now !' : 'See More Stories'}
                </button>
            )}
        </div>
    );
}

export default ScrollToTopButton;